<template>
  <div style="cursor:pointer">
    <!-- 头部组件 -->
    <header_public></header_public>
    <!-- top背景 -->
    <div class="top">
      <a href="javascript:void(0)" class="btns" rel="nofollow" @click="advice">
        <button class="topbutton" style="cursor:pointer">
          查看详情
          <img src="../../assets/images/shape.png" alt />
        </button>
      </a>
    </div>
    <!-- 产品介绍 -->
    <div class="topword"></div>
    <!-- mounth -->
    <div class="mounth"></div>
    <!-- 点击查看更多 -->
    <div class="house">
      <a href="javascript:void(0)" class="btns" rel="nofollow" @click="advice">
        <img src="../../assets/images/greenmore.png" alt />
      </a>
    </div>
    <!-- 输送方向 -->
    <div class="fangxiang"></div>
    <!-- 输送盒子1 -->
    <div class="shusong1">
      <div class="shusongbox1">
        <ul class="ul1">
          <li class="li1">前端云计算</li>
          <li class="li2">
            学员就业岗位涵盖:前端开发,全栈开发,web研发
            <br />,H5游戏开发,微信公众号小程序开发,前端架构师,
            <br />云计算开发,运维,测试等岗位,就业面广,待遇好!
            <br />选择前端云计算就是选择了互联网的未来!
          </li>
        </ul>
      </div>
      <div class="shusongbox2">
        <ul class="ul1">
          <li class="li1">大数据</li>
          <li class="li2">
            它具有海量的数据规模，快速的数据流转，多样的数据类型和价值密度低四大特征。专家预测截止到2020年，各个行业大数据相关人才缺口将达到100万，仅在大数据开发人才方面，将出现近30万的岗位需求。
          </li>
        </ul>
      </div>
      <div class="shusongbox3">
        <ul class="ul1">
          <li class="li1">5G物联网</li>
          <li class="li2">
            跟进最新技术启动及时，最新技术难题功课迅速，丰富项目
            实战不断更新，开发火爆应用实用为主。培养企业需要的中高级开发人才，连续6届毕业生，全班平均薪资超过10000。
          </li>
        </ul>
      </div>
    </div>
    <!-- 输送盒子2 -->
    <div class="shusong2">
      <div class="shusongbox3">
        <ul class="ul1">
          <li class="li1">视觉设计</li>
          <li class="li2">
            一个集静态动态与美的设计专业！能让画面“活”起来的专业。运用剪辑技术对素材的音效、动效以及转场等进行剪辑和混录，最终制作成一部完整的影片。
          </li>
        </ul>
      </div>
      <div class="shusongbox1">
        <ul class="ul1">
          <li class="li1">全栈开发</li>
          <li class="li2">
            互联网公司井喷式增长，人才需求急切，大型公司上门招聘全栈工程师！全栈工程师顺势而生，众多的公司都在抢占全栈人才，FACEBOOK声明：“我们只招全栈工程师”。
          </li>
        </ul>
      </div>
      <div class="shusongbox2">
        <ul class="ul1">
          <li class="li1">人工智能</li>
          <li class="li2">
            横跨多个互联网核心领域的人工智能，人工智能工程师可从事多领域编程开发工作。算法工程师，数据挖掘工程师，机器学习工程师图像处理工程师，语音识别工程师，爬虫工程师，Web全栈工程师，游戏开发工程师，互联网信息安全工程师。
          </li>
        </ul>
      </div>
    </div>
    <!-- 输送盒子3 -->
    <div class="shusong3">
      <div class="shusongbox1">
        <ul class="ul1">
          <li class="li1">互联网军校</li>
          <li class="li2">
            锤炼军人坚韧品质，掌握IT前沿技能，拥有领先大学学历！
            <br />学在互联网军校，争做IT领军人物!
          </li>
        </ul>
      </div>
      <div class="shusongbox2">
        <ul class="ul1">
          <li class="li1">精英学院</li>
          <li class="li2">
            培养学员大学计算机专业相关基础知识内容，为后期专业技术学习提供基础知识保障以及职业素养习惯。通过理论实践相结合，对接国家等级考试题库，清晰明确我们的考核标准，从而达到大学计算机专业基础水平
            。
          </li>
        </ul>
      </div>
    </div>
    <!-- 校园环境 学习课堂 -->
    <div class="classbg"></div>
    <div class="classbg1">
      <div class="classbox">
        <ul>
          <li class="classli1">
            <img src="../../assets/images/fengjing1.png" alt />
          </li>
          <li class="classli2">校园环境</li>
          <li class="classli3">
            积云实训校区坐落于昌平区十三陵镇，依山傍水，鸟语花香，学习氛围浓厚，基础设施完善，犹如世外桃源般为莘莘学子们提供了安静、优雅的学习生活环境。
            <br />在这里，没有繁杂的喧嚣声，孩子们可以全身心的投入到学习中去，待到毕业时，他们将犹如七剑下天山一般，寻找属于自己的机缘。
          </li>
          <!-- <li class="classli4"><a href="#">查看更多</a></li> -->
        </ul>
        <ul>
          <li class="classli1">
            <img src="../../assets/images/fengjing2.png" alt />
          </li>
          <li class="classli2">学习课堂</li>
          <li class="classli3">
            每天，讲师会给学员们制定详细的学习计划，同时也会对学员们的课堂纪律进行有序的维护，保证大家有良好的学习氛围以及学习上质的进展。另外我们会根据不同基础的学员制定不同的教学方案，让每一名学员及时跟上脚步，从而达到社会单位的用人需求，使学员的就业得到保障。
          </li>
          <!-- <li class="classli4"><a href="#">查看更多</a></li> -->
        </ul>
        <ul>
          <li class="classli1">
            <img src="../../assets/images/fengjing3.png" alt />
          </li>
          <li class="classli2">野外拓展</li>
          <li class="classli3">
            学习之余,我们的生活也是丰富多彩的!增强体质训练,重视素质拓展,我们一直在行动!
            <br />除了教授技术以外,我们还会培养学员的团队协作能力,沟通表达能力等,因为进入公司后我们不是一个人在战斗,需要同事之间、部门之间的沟通与配合.对职场生存法则的学习也为学员的就业奠定了必要基础。
          </li>
          <!-- <li class="classli4"><a href="#">查看更多</a></li> -->
          <div class="blueyuan">
            <img src="../../assets/images/blueyuan.png" alt />
          </div>
        </ul>
      </div>
    </div>
    <!-- 专业的管理团队 -->
    <div class="groupbg"></div>
    <!-- 组件/////////////////////////////////////////////// -->
    <div class="groupbg1">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) in teacherlist" :key="index">
            <ul class="yalan">
              <li class="groupli1" v-html="item.content">{{ item.content }}</li>
              <li class="groupli2">{{ item.title }}</li>
              <li class="groupli3">
                {{ item.extend ? item.extend.subtitle : "" }}
              </li>
              <li class="yalanimg">
                <img :src="item.image" alt />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="leftjiantou">
        <img src="../../assets/images/leftjiantou.png" alt />
      </div>
      <div class="rightjiantou">
        <img src="../../assets/images/rightjiantou.png" alt />
      </div>
    </div>

    <!-- 组件/////////////////////////////////////////////// -->
    <!-- 底部表单 -->
    <div class="bottombg">
      <div class="biaodanbox">
        <div class="name1">
          <p>全&nbsp;&nbsp;&nbsp;称</p>
          <input type="text" placeholder="我们该怎么称呼您？" v-model="name" />
          <p style="margin-top:1.5vw">电&nbsp;&nbsp;&nbsp;话</p>
          <input type="text" name id placeholder="请在此输入您的手机号" v-model="telephone" />
        </div>
        <div class="name2">
          <p>您的信息</p>
          <input type="text" name id placeholder="告诉我们更多您的想法！" v-model="content" />
        </div>
      </div>
      <img class="map" src="../../assets/images/map.png" alt />
      <img class="upup" src="../../assets/images/upup.png" alt @click="applyAction" />
    </div>
    <!-- 底部 -->
    <bottom_public></bottom_public>
  </div>
</template>
<script>
import header_public from "../../components/header_public/header_public";
import bottom_public from "../../components/bottom_public/bottom_public";
import Swiper from "swiper/js/swiper.min.js";
import "swiper/css/swiper.min.css";
const axios = require("axios").default;
export default {
  metaInfo: {
    title: "产品介绍-全栈开发-人工智能-视觉设计-[积云教育]",
    meta: [
      {
        name: "description",
        content:
          "各专业课程设置先进，强调可操作性的案例式教学，阶段性的项目实践，通通过项目开发和技术，锤炼专业技能，全面提升职业素质，实现从学员到技术骨干的转变。",
      },
      {
        name: "keywords",
        content: "全栈开发培训,人工智能培训,视觉设计培训",
      },
    ],
  },
  created() {
    this.getteacherlist(); //获取教师团队列表
  },
  data() {
    return {
      name: "",
      telephone: "",
      content: "",
      teacherlist: "",
      swiperTeacher: null,
    };
  },
  components: {
    header_public,
    bottom_public,
  },
  methods: {
    advice() {
      window.mantis.requestChat()
    },
    applyAction() {
      var url = encodeURIComponent(window.location.href);
      var param = {
        name: this.name,
        telephone: this.telephone,
        content: this.content,
        source_url: url,
      };

      axios
        .post("/api/audition/apply", param)
        .then(({ data: { msg = "申请成功" } }) => {
          alert(msg);
        })
        .catch(({ response: { data: { errors = "申请失败" } = {} } = {} }) => {
          alert(errors);
        });
    },
    //获取专业教师
    getteacherlist() {
      axios
        .get("/api/block/getItemsByName?name=" + encodeURIComponent("产品介绍"))
        .then((res) => {
          this.teacherlist = res.data.data; //教师团队列表数据
          this.$nextTick(() => {
            // 教师团队
            this.swiperTeacher && this.swiperTeacher.destroy();
            this.swiperTeacher = new Swiper(".swiper-container", {
              slidesPerView: 2,
              slidesPerGroup: 1,
              observer: true, //修改swiper自己或子元素时，自动初始化swiper
              observeParents: false, //修改swiper的父元素时，自动初始化swiper
              autoplay: {
                delay: 3000,
                disableOnInteraction: false, // 取消鼠标操作后的轮播暂停【无操作轮播继续】【参考链接1】
                stopOnLastSlide: false, // 在最后一页停止
              },
              pagination: {
                el: ".teacher-spit",
                clickable: true,
              },
              navigation: {
                nextEl: ".rightjiantou",
                prevEl: ".leftjiantou",
                hideOnClick: true,
              },
              loop: true,
            });
          });
        });
    },
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
.swiper-container {
  width: 62.5vw;
  margin: auto;
  --swiper-theme-color: #ff6600;
  /* 设置Swiper风格 */
  --swiper-navigation-color: #fff;
  /* 单独设置按钮颜色 */
  --swiper-navigation-size: 30px;
  /* 设置按钮大小 */
}

.swiper-slide {
  width: 50% !important;
  height: 30.27vw !important;
  box-sizing: border-box;
  padding: 0 1vw;
}
</style>
<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
}

.top {
  width: 100%;
  height: 49.58vw;
  background: url("~@/assets/images/topbg.png") no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  position: relative;
}

.topbutton {
  width: 11.45vw;
  height: 3.9vw;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 30px 35px 0px rgba(31, 110, 227, 0.15);
  border-radius: 20px 0px 20px 20px;
  font-size: 1.375rem;
  font-family: PingFang;
  font-weight: 600;
  color: rgba(55, 71, 79, 1);
  border-style: none;
  position: absolute;
  top: 29vw;
  left: 19vw;
}

.topbutton img {
  width: 1.71vw;
  height: 1vw;
  margin-left: 2vw;
}

.topword {
  width: 100%;
  height: 12.9vw;
  background: url("~@/assets/images/topword.png") no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}

.mounth {
  width: 100%;
  height: 71.82vw;
  background: url("~@/assets/images/riqi.png") no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}

.house {
  width: 100%;
  height: 41.45vw;
  background: url("~@/assets/images/house.png") no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  position: relative;
}

.house img {
  width: 14.58vw;
  height: 3.9vw;
  position: absolute;
  top: 1.5vw;
  left: 43vw;
}

// 输送方向
.fangxiang {
  width: 100%;
  height: 17.96vw;
  background: url("~@/assets/images/fangxiang.png") no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}

.shusong1 {
  width: 100%;
  height: 79.47vw;
  background: url("~@/assets/images/shusong1.png") no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  position: relative;
}

.shusong1>div:hover,
.shusong2>div:hover,
.shusong3>div:hover {
  transform: translateY(-20px);
  transition: all 1s;
}

.shusongbox1 {
  width: 28.64vw;
  height: 18.54vw;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 27px 43px 0px rgba(32, 94, 171, 0.1);
  border-radius: 20px;
  position: absolute;
  top: 14.5vw;
  left: 19vw;
}

.shusong1 ul {
  width: 20.15vw;
  height: 11.51vw;
  margin: 0 auto;
  // background-color: aquamarine;
  padding-top: 3.2vw;
  text-align: left;
}

.shusong1 .li1 {
  font-size: 1.5rem;
  font-family: PingFang;
  font-weight: 800;
  color: rgba(38, 50, 56, 1);
  margin-bottom: 1.5vw;
}

.shusong1 .li2 {
  font-size: 1.085rem;
  color: #546e7a;
  line-height: 1.871vw;
  font-family: PingFang-SC-Heavy;
}

.shusongbox2 {
  width: 28.64vw;
  height: 18.54vw;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 27px 43px 0px rgba(32, 94, 171, 0.1);
  border-radius: 20px;
  position: absolute;
  top: 36vw;
  right: 19vw;
}

.shusongbox3 {
  width: 28.64vw;
  height: 18.54vw;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 27px 43px 0px rgba(32, 94, 171, 0.1);
  border-radius: 20px;
  position: absolute;
  top: 58vw;
  left: 19vw;
}

//输送方向二
.shusong2 {
  width: 100%;
  height: 66.14vw;
  background: url("~@/assets/images/shusong2.png") no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  position: relative;
}

.shusong2 .shusongbox1 {
  width: 28.64vw;
  height: 18.54vw;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 27px 43px 0px rgba(32, 94, 171, 0.1);
  border-radius: 20px;
  position: absolute;
  top: 22.5vw;
  left: 19vw;
}

.shusong2 ul {
  width: 20.15vw;
  height: 11.51vw;
  margin: 0 auto;
  // background-color: aquamarine;
  padding-top: 3.2vw;
  text-align: left;
}

.shusong2 .li1 {
  font-size: 1.5rem;
  font-family: PingFang;
  font-weight: 800;
  color: rgba(38, 50, 56, 1);
  margin-bottom: 1.5vw;
}

.shusong2 .li2 {
  font-size: 1.085rem;
  color: #546e7a;
  line-height: 1.871vw;
  font-family: PingFang-SC-Heavy;
}

.shusong2 .li3 {
  font-size: 1.125rem;
  font-family: PingFang;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  text-align: right;
  margin-top: 1.2vw;
}

.shusong2 .shusongbox2 {
  width: 28.64vw;
  height: 18.54vw;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 27px 43px 0px rgba(32, 94, 171, 0.1);
  border-radius: 20px;
  position: absolute;
  top: 44vw;
  right: 19vw;
}

.shusong2 .shusongbox3 {
  width: 28.64vw;
  height: 18.54vw;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 27px 43px 0px rgba(32, 94, 171, 0.1);
  border-radius: 20px;
  position: absolute;
  top: 0;
  left: 51.5vw;
}

//输送方向3
.shusong3 {
  width: 100%;
  height: 46.97vw;
  background: url("~@/assets/images/shusong3.png") no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  position: relative;
}

.shusong3 .shusongbox1 {
  width: 28.64vw;
  height: 18.54vw;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 27px 43px 0px rgba(32, 94, 171, 0.1);
  border-radius: 20px;
  position: absolute;
  top: 0;
  left: 19vw;
}

.shusong3 ul {
  width: 20.15vw;
  height: 11.51vw;
  margin: 0 auto;
  // background-color: aquamarine;
  padding-top: 3.2vw;
  text-align: left;
}

.shusong3 .li1 {
  font-size: 1.5rem;
  font-family: PingFang;
  font-weight: 800;
  color: rgba(38, 50, 56, 1);
  margin-bottom: 1.5vw;
}

.shusong3 .li2 {
  font-size: 1.085rem;
  color: #546e7a;
  line-height: 1.871vw;
  font-family: PingFang-SC-Heavy;
}

.shusong3 .li3 {
  font-size: 1.125rem;
  font-family: PingFang;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  text-align: right;
  // margin-top: 1.2vw;
}

.shusong3 .shusongbox2 {
  width: 28.64vw;
  height: 18.54vw;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 27px 43px 0px rgba(32, 94, 171, 0.1);
  border-radius: 20px;
  position: absolute;
  top: 22vw;
  right: 19vw;
}

//校园环境 学习课堂
.classbg {
  width: 100%;
  height: 7.5vw;
  background: url("~@/assets/images/class.png") no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}

.classbg1 {
  width: 100%;
  height: 46.56vw;
  background: url("~@/assets/images/class2.png") no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}

.classbox {
  width: 62.5vw;
  height: 35.52vw;
  margin: 0 auto;
  // background-color: aquamarine;
  display: flex;
  justify-content: space-between;
}

.classbox ul {
  width: 18.48vw;
  height: 100%;
  background-color: #fff;
  box-shadow: 0px 27px 43px 0px rgba(32, 94, 171, 0.1);
  border-radius: 20px;
  position: relative;
}

.classbox ul img {
  width: 100%;
}

.classli2 {
  width: 14.06vw;
  margin: 0 auto;
  // background-color: red;
  font-size: 1.5rem;
  color: #263238;
  text-align: left;
  font-family: PingFang-SC-Heavy;
  font-weight: 550;
}

.classli3 {
  width: 14.06vw;
  margin: 0 auto;
  margin-top: 1.5vw;
  // background-color: red;
  color: #546e7a;
  font-size: 1.11rem;
  line-height: 1.56vw;
  text-align: left;
  font-family: PingFang-SC-Heavy;
}

.classli4 {
  width: 14.06vw;
  margin: 0 auto;
  // background-color: red;
  margin-top: 2vw;
  text-align: left;
  font-size: 1.125rem;
}

.classli4 a {
  text-decoration: none;
  color: #2870db;
}

.blueyuan {
  position: absolute;
  top: 34vw;
  left: 15vw;
}

//专业的管理团队
.groupbg {
  width: 100%;
  height: 9.47vw;
  background: url("~@/assets/images/group.png") no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}

//名片
.groupbg1 {
  width: 100%;
  height: 36.197vw;
  background: url("~@/assets/images/douhao.png") no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  position: relative;
  font-family: PingFang;
}

.groupbox {
  width: 62.5vw;
  height: 27vw;
  margin: 0 auto;
  // background-color: red;
  display: flex !important;
  justify-content: space-between !important;
  position: relative;
}

.yalan {
  width: 100%;
  // height:19.27vw;
  // height: 22vw;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 27px 20px 0px rgba(207, 216, 220, 0.15);
  border-radius: 20px 20px 60px 20px;
  margin-top: 3vw;
  position: relative;
}

.yalan li {
  width: 20.88vw;
  margin: 0 auto;
  text-align: left;
  font-family: PingFang;
  // font-weight:bold;
}

.yalan li:nth-child(2) {
  margin: 1vw auto 0.5vw;
}

.groupli1 {
  // margin-top: 4.84vw;
  font-size: 1rem;
  color: #546e7a;
  line-height: 1.56vw;
  padding-top: 3.84vw;
  box-sizing: border-box;
}

.groupli2 {
  margin-top: 1.5vw;
  font-size: 1.25rem;
  color: #2fc9ef;
  font-weight: 600;
}

.groupli3 {
  margin-top: 1vw;
  padding-bottom: 2vw;
  font-size: 1.25rem;
  color: #263238;
  font-weight: bold;
}

.dangwei {
  width: 100% !important;
  height: 23.43vw !important;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 27px 20px 0px rgba(207, 216, 220, 0.15);
  border-radius: 20px 20px 60px 20px;
  position: relative;
  margin-top: 3vw;
}

.dangwei li:nth-child(1) {
  padding: 2.84vw 4.2vw 0;
  text-align: left;
}

.dangwei li:nth-child(2) {
  margin: 0.8vw auto 0.5vw !important;
}

.dangwei li:nth-child(2),
.dangwei li:nth-child(3) {
  width: 20.88vw;
  margin: 0 auto;
  text-align: left;
}

.yalanimg {
  width: 7.08vw !important;
  height: 7.55vw;
  position: absolute;
  top: -3vw;
  left: 3.5vw;
}

.dangweiimg {
  width: 7.08vw;
  height: 7.55vw;
  position: absolute;
  top: -3vw;
  right: 18vw;
}

.yalanimg img,
.dangweiimg img {
  //width: 100%;
  height: 100%;
}

.leftjiantou {
  width: 3.95vw;
  height: 3.95vw;
  position: absolute;
  top: 9vw;
  left: 12vw;
  outline: none;
}

.rightjiantou {
  width: 6.95vw;
  height: 6.95vw;
  position: absolute;
  top: 9vw;
  right: 11vw;
  outline: none;
}

.leftjiantou img,
.rightjiantou img {
  width: 100%;
  height: 100%;
}

//底部
.bottombg {
  width: 100%;
  height: 49.32vw;
  background: url("~@/assets/images/bottombg.png") no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  position: relative;
}

.biaodanbox {
  width: 37.5vw;
  height: 15.6vw;
  // background-color: red;
  position: absolute;
  top: 17vw;
  left: 23.8vw;
  display: flex;
  justify-content: space-between;
}

.biaodanbox p {
  font-size: 1rem;
  font-family: PingFang;
  font-weight: bold;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 1vw;
  text-align: left;
}

.name1 input {
  width: 18.22vw;
  height: 3.64vw;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 15px 35px 0px rgba(31, 110, 227, 0.05);
  border-radius: 10px 0px 10px 10px;
  border-style: none;
  padding-left: 1vw;
  box-sizing: border-box;
}

.name2 input {
  width: 18.22vw;
  height: 11vw;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 15px 35px 0px rgba(31, 110, 227, 0.05);
  border-radius: 10px 0px 10px 10px;
  border-style: none;
  padding-left: 1vw;
  padding-bottom: 8vw;
  box-sizing: border-box;
  // margin-top: 0.5vw;
}

.map {
  width: 27.1vw;
  height: 30.4vw;
  position: absolute;
  top: 5vw;
  right: 7.39vw;
}

.upup {
  width: 9.37vw;
  height: 3.9vw;
  position: absolute;
  bottom: 14vw;
  left: 52vw;
}

.foot_box {
  margin-top: 0 !important;
}</style>
